// src/pages/Booking.js

import React, { useEffect, useState } from 'react';
import BackButton from '../components/BackButton';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import SyntheticPopup from '../synthetic/SyntheticPopup';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/Booking.css'; // Import the CSS
import emailjs from 'emailjs-com';
import { hidePopup, setVideoFile, showPopup } from '../features/popupSlice';

const Booking = () => {
	const { t } = useTranslation();
	const isPopupVisible = useSelector((state) => state.popup.isPopupVisible);
	const [showEndButtons, setShowEndButtons] = useState(false);
	const dispatch = useDispatch();
	// hack for email sender
	const [hack, setHack] = useState(false);
	const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
	const [validEmail, setValidEmail] = useState(true);
	const [validName, setValidName] = useState(true);
	const [message, setMessage] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [showError, setShowError] = useState(false);

	const handleBackBtn = () => {
		setHack(false);
	};

	const handleVideoEnd = () => {
		setShowEndButtons(true);
	};

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		date: '',
		message: '',
	});

	const maxMessageLenght = 300;
	
	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === 'name') {
      const isValidName = /^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$/.test(value);

      if (!isValidName) {
        setValidName(false);
      } else {
        setValidName(true);
      }
	  }
		
		if (name === 'email') {
				const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

				if (!isValidEmail) {
					setValidEmail(false);
			} else {
					setValidEmail(true);
			}
	}

	if (name === 'message') {
    setMessage(value);
  }

		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setShowError(true);
		if (!validName || !validEmail || formData.email.trim() === '' || formData.name.trim() === '') {
			return;
	}
	
		setHack(true);
		setShowError(false);

		// emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
		//     .then((result) => {
		//         console.log(result.text);
		//         alert("The booking has been successfully sent!");
		//     }, (error) => {
		//         console.log(error.text);
		//         alert("There was an error in sending. Please try again.");
		//     });
	};

	const togglePopupVisibility = () => {
		switch (currentLanguage) {
			case 'de':
				dispatch(setVideoFile('/ready-de.mp4'));
				break;
			case 'en':
				dispatch(setVideoFile('/ready-en.mp4'));
				break;
			case 'fi':
				dispatch(setVideoFile('/ready-fi.mp4'));
				break;
			default:
				dispatch(setVideoFile('/ready-en.mp4'));
				break;
		}
		dispatch(hidePopup());
	};

	useEffect(() => {
		togglePopupVisibility();
	}, [dispatch, currentLanguage]);

	return (
		<div className="booking-page">
			<Header transparentHeader={true} />
			{isPopupVisible && (
				<SyntheticPopup
					position="right"
					autoPlay={false}
					showEndButtons={showEndButtons}
					showStartTestButton={false}
					className={
						isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'
					}
				/>
			)}
			<button
				className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
				onClick={() => dispatch(showPopup())}
			>
				<img src="/avatar.png" alt="Toggle Popup" />
			</button>
			<div className="booking-content">
				<h1>{t('Booking').toUpperCase()}</h1>
				{!hack ? (
					<form onSubmit={handleSubmit}>
						<input
							type="text"
							name="name"
							placeholder={t('Name') + ' *'}
							onChange={handleChange}
							required=""
							pattern="^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$" 
							title={t('titleName')} 
							value={formData.name}
              minLength={2}
						/>
						{!validName && <span className="error">{t('invalidName')}</span>}
						<input
							type="email"
							name="email"
							placeholder="Email *"
							onChange={handleChange}
							required=""
							pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
							title={t('titleEmail')}
							value={formData.email}
						/>
						{!validEmail && <span className="error">{t('invalidEmail')}</span>}
						<input
							type="date"
							name="date"
							defaultValue={new Date().toISOString().split('T')[0]}
							min={new Date().toISOString().split('T')[0]}
							lang={t('lang')}
							onChange={handleChange}
							required
							style={{ fontFamily: 'Arial, sans-serif' }}
						/>
						<textarea
							name="message"
							placeholder={t('Comment')}
							onChange={handleChange}
							style={{ fontFamily: 'Arial, sans-serif' }}
							onFocus={() => setIsFocused(true)}
            	onBlur={() => setIsFocused(false)}
							maxLength={maxMessageLenght}
						/>
						 {isFocused && message && (<p className='message-lenght'>Remaining characters: {maxMessageLenght - message.length}</p>)}
						<button type="submit">{t('BookNow')}</button>
						{showError && (!formData.name.trim() || !formData.email.trim()) && (
            <span className="errorRequired">{t('required')}</span>
            )}
					</form>
				) : (
					<div>
						<h2>{t('SuccessfulSending')}</h2>
						<p>{t('ReservationConfirmation')}</p>
						<button type="button" onClick={handleBackBtn}>
							{t('Back')}
						</button>
					</div>
				)}
			</div>
			{/*<BackButton/>*/}
		</div>
	);
};

export default Booking;
