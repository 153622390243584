// src/i18n.js
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import fiTranslation from "./locales/fi/translation.json";
import deTranslation from "./locales/de/translation.json";

const selectedLanguage = localStorage.getItem('selectedLanguage');
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslation
            },
            fi: {
                translation: fiTranslation
            },
            de: {
                translation: deTranslation
            }
        },
        lng: selectedLanguage || 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    }).then(() => {
    console.log('i18n initialized successfully');
})
    .catch((error) => {
        console.error('Failed to initialize i18n:', error);
    });

    i18n.on('languageChanged', (lng) => {
        localStorage.setItem('selectedLanguage', lng);
      });
      
export default i18n;
