// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import './i18n';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Blog from "./pages/Blog";
import ActiveHoliday from "./pages/ActiveHoliday";
import RentalTerms from "./pages/RentalTerms";
import SafetyOnWater from "./pages/SafetyOnWater";
import Training from "./pages/Training";
import Booking from "./pages/Booking";
import Houseboat from "./pages/Houseboat";
import Footer from "./components/Footer";
import Feedback from 'react-bootstrap/esm/Feedback';

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/contact/feedback" element={<Feedback />} />
                    {/* <Route path="/blog" element={<Blog />} /> */}
                    <Route path="/active-holiday" element={<ActiveHoliday />} />
                    <Route path="/rental-terms" element={<RentalTerms />} />
                    <Route path="/safety-on-water" element={<SafetyOnWater />} />
                    <Route path="/training" element={<Training />} />
                    <Route path="/booking" element={<Booking />} />
                    <Route path="/houseboat" element={<Houseboat />} />
                </Routes>
                <Footer />
            </Router>
        </Provider>
    );
};

export default App;
