// src/pages/Home.js
import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import {useTranslation} from 'react-i18next';
import "../styles/Home.css";
import SyntheticPopup from '../synthetic/SyntheticPopup';
import {useDispatch, useSelector} from 'react-redux';
import {showPopup, hidePopup, setVideoFile} from '../features/popupSlice';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {useNavigate} from 'react-router-dom';


function Home() {
    const {t} = useTranslation();
    const isMenuOpen = useSelector(state => state.burgerMenu.isOpen);
    const dispatch = useDispatch();
    const isHeaderVisible = useSelector(state => state.burgerMenu.isHeaderVisible);
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const galleryRef = React.useRef(null);
    const [activeSection, setActiveSection] = useState(null);
    const [showEndButtons, setShowEndButtons] = useState(false);
    const videoFile = useSelector(state => state.popup.videoFile);
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();

    const handleVideoEnd = () => {
        setShowEndButtons(true);
    };

    const readyVideoFiles = [
        "/ready-en.mp4", "/ready-fi.mp4", "/ready-de.mp4",
    ]

    const readyVideoLanguage = {
        en: readyVideoFiles[0],
        fi: readyVideoFiles[1],
        de: readyVideoFiles[2],
    }

    const togglePopupVisibility = () => {
        if (activeSection !== 'section-2') {
            if (readyVideoFiles.includes(videoFile) || sessionStorage.getItem('/home-select-lang-en.mp4') === 'watched') {
                dispatch(setVideoFile(readyVideoLanguage[currentLanguage]));
                dispatch(showPopup());
                setShowEndButtons(true);
                const timeoutId = setTimeout(() => {
                    setShowEndButtons(false);
                    dispatch(hidePopup());
                }, 7000);
                return () => {
                    clearTimeout(timeoutId);
                };
            }
        }

        if (activeSection) {
            console.log(activeSection);
            dispatch(setVideoFile(videoMap[activeSection]));
            dispatch(showPopup());
        }
    };


    const galleryImages = [
        {original: '/main-gallery1-1.jpg'},
        {original: '/main-gallery1-2.jpg'},
        {original: '/main-gallery1-3.jpg'},
        {original: '/main-gallery1-4.jpg'},
        {original: '/main-gallery1-5.jpg'},
        {original: '/main-gallery1-6.jpg'},
        {original: '/main-gallery1-7.jpg'},
        {original: '/main-gallery1-8.jpg'},
        {original: '/main-gallery2-1.jpg'},
        {original: '/main-gallery2-2.jpg'},
        {original: '/main-gallery2-3.jpg'},
        {original: '/main-gallery2-4.jpg'},
        {original: '/main-gallery3-1.jpg'},
        {original: '/main-gallery3-2.jpg'},
        {original: '/main-gallery3-3.jpg'},
        {original: '/main-gallery3-4.jpg'},
        {original: '/main-gallery3-5.jpg'},
        {original: '/main-gallery4-1.jpg'},

    ];

    const videoFiles = {
        en: {
            1: '/home-main-gallery-photo1-2-en.mp4',
            8: '/home-main-gallery-photo2-1-en.mp4',
            12: '/home-main-gallery-photo3-1-en.mp4',
            17: '/home-main-gallery-photo4-1-en.mp4',
        },
        fi: {
            1: '/home-main-gallery-photo1-2-fi.mp4',
            8: '/home-main-gallery-photo2-1-fi.mp4',
            12: '/home-main-gallery-photo3-1-fi.mp4',
            17: '/home-main-gallery-photo4-1-fi.mp4',
        },
        de: {
            1: '/home-main-gallery-photo1-2-de.mp4',
            8: '/home-main-gallery-photo2-1-de.mp4',
            12: '/home-main-gallery-photo3-1-de.mp4',
            17: '/home-main-gallery-photo4-1-de.mp4',
        },
    };

    const videoMap = {
        "section-1": "/home-select-lang-en.mp4",
        "section-2": "/home-intro-gallery-en.mp4",
    };

    const handleSlide = (currentIndex) => {

        const popupImageIndexes = [1, 8, 12, 17];

        if (popupImageIndexes.includes(currentIndex)) {
            const videoFile = videoFiles[currentLanguage][currentIndex];
            if (!isPopupVisible) {
                dispatch(showPopup());
            }

            dispatch(setVideoFile(videoFile));

            if (currentIndex === 17) {
                handleVideoEnd();
                console.log(showEndButtons);
            }
        } else if (isPopupVisible && currentIndex === 0) {
            dispatch(hidePopup());
        }
    };


    const handleButtonClick = (item) => {
        console.log("Button clicked:", item);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            {threshold: 0.8}
        );

        document.querySelectorAll('.section').forEach((section) => {
            observer.observe(section);
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        dispatch(setVideoFile("/home-select-lang-en.mp4"));
        handleVideoEnd();
    }, [dispatch])

    return (
        <div className="home">
            <Header transparentHeader={true}/>
            <div className="section" id="section-1" style={{backgroundImage: 'url(/bg-main.jpg)'}}>
                {isPopupVisible && <SyntheticPopup
                    position="right"
                    autoPlay={true}
                    showEndButtons={showEndButtons}
                    showStartTestButton={false}
                    className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
                />}
                <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                        onClick={togglePopupVisibility}>
                    <img src="/avatar.png" alt="Toggle Popup"/>
                </button>

                <div className={`page-content ${isHeaderVisible ? '' : 'hidden'}`}>
                    <div className="section-title section-1">
                        <h1 className="section-title section-1">{t('Houseboat').toUpperCase()}</h1>
                    </div>

                    <div className="main-page-text section-1">
                        {t('uniqueExperience')}
                    </div>
                </div>
            </div>

            <div className="section" id="section-2" style={{backgroundImage: 'url(/bg-main1.jpg)'}}>
                <h1 className="section-title section-2">{t('galleryTitle')}</h1>
                <div className="main-page-text section-2">
                    {t('familyActivities')}
                </div>
                <div className="section gallery-section">
                    <ImageGallery
                        items={galleryImages}
                        onSlide={handleSlide}
                        showBullets={true}
                        showThumbnails={false}
                        infinite={false}/>
                </div>
                <button className="transparent-button" onClick={() => navigate('/houseboat')}>
                    {t('whatIsHouseboat')}
                </button>
            </div>
        </div>
    );
}

export default Home;
