import React from 'react';
import '../styles/TrioImageComponent.css';
import { useTranslation } from 'react-i18next';

const TrioImageComponent = ({ title, text, textSep, text2, image1, image2, image3, backgroundImage }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

    return (
      <div className={`trio-container ${backgroundImage ? 'with-bg' : ''}`} style={{backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none'}}>
        <div className="trio-box">
          <div className="trio-title-container">{title}</div>
          <div className="trio-text-container">
            {text}
            <span className={currentLanguage !== "fi" && 'tooltip-container'} style={{ fontSize: '1.25rem' }}>
              {textSep}
              {currentLanguage !== "fi" && 
                <span className="tooltip-content">
                {t('IPALakeName')}
              </span>}
            </span>
            {text2}
          </div>
          <div className="trio-image-container">
            <img src={image1} className="img" alt="Active Order Visual" />
            <img src={image2} className="img" alt="Active Order Visual" />
            <img src={image3} className="img" alt="Active Order Visual" />
          </div>
        </div>
        
      </div>
    );
};

export default TrioImageComponent;