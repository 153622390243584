export const getCustomStyles = (isMobile, currentPage) => ({
    control: (provided, state) => ({
        ...provided,
        className: 'mobile-select-control',
        minWidth: 100,
        backgroundColor: isMobile ? 'transparent' : '#274286',
        fontSize: '14px',
        border: isMobile ? 'none' : state.isFocused ? '1px solid #768daf' : '1px solid #274286',
        boxShadow: isMobile ? 'none' : state.isFocused ? '0 0 0 1px #768daf' : null,
        transition: 'all 0.3s ease',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#274286', /* цвет текста в меню */
        backgroundColor: '#BCDFFB',
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isSelected ? '#274286' : isFocused ? 'rgba(39, 66, 134, 0.1)' : 'transparent',
        color: isSelected ? '#ffffff' : '#274286',
        "&:hover": {
            backgroundColor: '#e2e2e2'
        },
        transition: 'all 0.3s ease',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: isMobile ? (currentPage === '' || currentPage === 'houseboat') ? '#f6f6f6' : '#274286' : '#f6f6f6',
        transition: 'all 0.3s ease',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#f6f6f6',
        transition: 'all 0.3s ease',
    }),
    indicatorSeparator: () => ({
        display: 'none',
        transition: 'all 0.3s ease',
    }),
});