// src/app/store.js

import {combineReducers, configureStore} from '@reduxjs/toolkit';
import burgerMenuSlice from "../features/burgerMenuSlice";
import popupReducer from "../features/popupSlice";
import languageMenuSlice from "../features/languageMenuSlice";

const rootReducer = combineReducers({
    burgerMenu: burgerMenuSlice,
    popup: popupReducer,
    languageMenu: languageMenuSlice,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;

