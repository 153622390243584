// src/pages/SafetyOnWater.js

import React, {useEffect, useState} from 'react';
import BackButton from '../components/BackButton';
import {useTranslation} from 'react-i18next';
import Header from "../components/Header";
import SyntheticPopup from "../synthetic/SyntheticPopup";
import {useDispatch, useSelector} from "react-redux";
import '../styles/SafetyOnWater.css'; // Import the CSS
import ChessOrderComponent from '../components/ChessOrderComponent';
import { hidePopup, setVideoFile, showPopup } from '../features/popupSlice';

const SafetyOnWater = () => {
    const { t } = useTranslation();
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const [showEndButtons, setShowEndButtons] = useState(false);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleVideoEnd = () => {
        setShowEndButtons(true);
    };

    const content = [
        { title: "PayAttentionWeather", text: "CheckWeatherForecast", image: "safety-1.jpg" },
        { title: "BePreparedWithClothing", text: "RememberWaterCooler", image: "safety-2.jpg", backgroundImage: "bg-safety.jpg" },
        { title: "UseCommonSense", text: "CommonSenseFoundation", image: "safety-3.jpg" },
        { title: "CaptainAssistant", text: "TripAssistant", image: "safety-4.jpg", backgroundImage: "bg-safety.jpg" },
        { title: "FireSafety", text: "SmokingOnBoard", image: "safety-5.jpg" },
        { title: "PlanYourRoute", text: "CarefulRoutePlanning", image: "safety-6.jpg", backgroundImage: "bg-safety.jpg" },
        { title: "CheckLifeJackets", text: "LifeJackets",  image: "safety-7.jpg" },
        { title: "ConsumeBeverages", text: "TheConsumptionOfBeverages", image: "safety-8.jpg", backgroundImage: "bg-safety.jpg" },
        { title: "Swimming", text: "SwimmingLocation", image: "safety-9.jpg" },
        { title: "EducateYourself", text: "AttendPreTripTraining", image: "safety-10.jpg", backgroundImage: "bg-safety.jpg" },
    ];

    const togglePopupVisibility = () => {
        switch (currentLanguage) {
            case "de":
                dispatch(setVideoFile("/ready-de.mp4"));
                break;
            case "en":
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
            case "fi":
                dispatch(setVideoFile("/ready-fi.mp4"));
                break;
            default:
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
        }
        dispatch(hidePopup());
    };

    useEffect(() => {
        togglePopupVisibility();
    }, [dispatch]);

    return (
        <div>
            <Header transparentHeader={true}/>
            {isPopupVisible && <SyntheticPopup
                position="right"
                autoPlay={true}
                showEndButtons={showEndButtons}
                showStartTestButton={false}
                className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
            />}
            <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                    onClick={() => {dispatch(showPopup()); handleVideoEnd()}}>
                <img src="/avatar.png" alt="Toggle Popup"/>
            </button>
            <div className="safety-water-page">
                <h1 className="section-title">{t('SafetyOnWater')}</h1>
                <h4 className="section-title-description">{t('SafetyAndBehaviorRulesOnWater')}</h4>
                {content.map((item, index) => (
                    <ChessOrderComponent
                        key={index}
                        index={index}
                        title={t(item.title)}
                        text={t(item.text)}
                        image={item.image}
                        backgroundImage={item.backgroundImage}
                    />
                ))}
            </div>
            {/*<BackButton/>*/}
        </div>
    );
};

export default SafetyOnWater;