// burgerMenuSlice.js
import { createSlice } from '@reduxjs/toolkit';

const burgerMenuSlice = createSlice({
    name: 'burgerMenu',
    initialState: {
        isOpen: false,
        isHeaderVisible: true,
    },
    reducers: {
        toggleMenu: (state) => {
            state.isOpen = !state.isOpen;
        },
        toggleHeaderVisibility: (state) => {
            state.isHeaderVisible = !state.isHeaderVisible;
        },
    },
});

export const { toggleMenu, toggleHeaderVisibility } = burgerMenuSlice.actions;
export default burgerMenuSlice.reducer;
