import React, {useEffect, useRef, useState} from 'react';
import './SyntheticPopup.css';
import {useNavigate, useLocation } from 'react-router-dom';
import { hidePopup, setVideoFile } from '../features/popupSlice';
import {useDispatch, useSelector} from "react-redux";
import {showLanguageMenu} from "../features/languageMenuSlice";
import {showPopup} from "../features/action";
import { useTranslation } from 'react-i18next';


function SyntheticPopup({
                            position = 'right',
                            autoPlay = false,
                            showEndButtons = false,
                            showStartTestButton = false,
                            onVideoControl
                        }) {

    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const videoFile = useSelector(state => state.popup.videoFile);
    const dispatch = useDispatch();
    const shouldAnimateIn = isPopupVisible;
    const shouldAnimateOut = !isPopupVisible;
    const animationClass = position === 'right' ? 'animate-right' : 'animate-left';
    const animationExitClass = position === 'right' ? 'animate-out-right' : 'animate-out-left';
    const [showButtons, setShowButtons] = useState(false);
    const [volume, setVolume] = useState(0.1); // начальное значение громкости 1 (максимальное)
    const videoRef = useRef(null); // ссылка на элемент видео
    const [isCloseIconVisible, setIsCloseIconVisible] = useState(true);
    const location = useLocation();
    const { t } = useTranslation();
    const nonRepeatableVideos = [
        "/home-select-lang-en.mp4",
    ];

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        videoRef.current.volume = newVolume; // устанавливаем громкость для видео
    };

    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleYesClick = () => {
        let videoPath;
        if (
            videoFile === "/ready-en.mp4" ||
            videoFile === "/ready-fi.mp4" ||
            videoFile === "/ready-de.mp4") {
                navigate('/booking');
                return;
        }
        if (location.pathname === "/training") {
            setShowButtons(false);
            setIsCloseIconVisible(true);
            switch (currentLanguage) {
                case 'en':
                    videoPath = "/training-video-gallery-en.mp4";
                    break;
                case 'fi':
                    videoPath = "/training-video-gallery-fi.mp4";
                    break;
                case 'de':
                    videoPath = "/training-video-gallery-de.mp4";
                    break;
                default:
                    videoPath = "/training-video-gallery-en.mp4";
            }
            dispatch(setVideoFile(videoPath));
        } else if (location.pathname === "/anotherPage") {
            console.log("test");
        } else {
            navigate('/houseboat');
        }
    };


    const handleNoClick = () => {
        dispatch(hidePopup()); // Закрыть попап, используя Redux
    };

    const handleStartTestClick = () => {
        navigate('/quiz'); // Перенаправление на страницу квиза
    };

    const handleVideoLoaded = () => {
        if (videoRef.current && videoRef.current.readyState === 4) {
            videoRef.current.play().catch(error => {
                console.error("Ошибка при воспроизведении видео:", error);
            });
        }
    };

    const handleVideoEnded = () => {
        switch(videoFile) {
            case "/home-select-lang-en.mp4":
                dispatch(showLanguageMenu());
                dispatch(hidePopup());
                break;
            case "/home-main-gallery-photo4-1-en.mp4":
            case "/home-main-gallery-photo4-1-fi.mp4":
            case "/home-main-gallery-photo4-1-de.mp4":
                setIsCloseIconVisible(false);
                break;
            case "/ready-en.mp4":
            case "/ready-fi.mp4":
            case "/ready-de.mp4":
                if (location.pathname === "/booking") {
                    dispatch(hidePopup());
                }
                setIsCloseIconVisible(false);
                break;
            case "/training-intro-en.mp4":
            case "/training-intro-fi.mp4":
            case "/training-intro-de.mp4":
                setIsCloseIconVisible(false);
                break;
            default:
                dispatch(hidePopup());
                break;
        }
        if (nonRepeatableVideos.includes(videoFile)) {
            sessionStorage.setItem(videoFile, 'watched');
            console.log(sessionStorage.getItem(videoFile));
        }
    };


    const onClose = () => {
        dispatch(hidePopup()); // Скрываем попап, изменяя состояние в Redux
    };

    useEffect(() => {
        if (videoRef.current) {
            if (nonRepeatableVideos.includes(videoFile) && sessionStorage.getItem(videoFile) === 'watched') {
                console.log("watched");
                dispatch(hidePopup());
                return;
            }

            if (!videoRef.current.paused) {
                videoRef.current.pause();
            }
            videoRef.current.load(); // перезагрузка видео
            console.log(videoRef.current);
        }
    }, [videoFile, dispatch]);

    useEffect(() => {
        // Передаем функции управления видео родительскому компоненту                                
        if (onVideoControl) {
            onVideoControl({
                play: () => videoRef.current && videoRef.current.play(),
                pause: () => videoRef.current && videoRef.current.pause()
            });
        }
    }, [onVideoControl]);


    return (
        <div
            className={`synthetic-popup ${position} ${isPopupVisible ? animationClass : animationExitClass}`}>
            <video playsInline
                   ref={videoRef}
                   onLoadedData={handleVideoLoaded}
                   autoPlay={autoPlay}
                   className="avatar-video"
                   onEnded={() => {
                    setShowButtons(true);
                    handleVideoEnded();
                }}
            >
                <source src={videoFile} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>

            <div className="popup-controls">
                {isCloseIconVisible && (
                    <i className="cl-btn-7" onClick={onClose}></i>
                )}
                {/*<i className="fas fa-play play-icon" onClick={() => videoRef.current.play()}></i>*/}
                {/*<i className="fas fa-pause pause-icon" onClick={() => videoRef.current.pause()}></i>*/}
            </div>

            {/*<div className="volume-control-popup">*/}
            {/*    <i className="fa fa-volume-up volume-icon-popup"></i>*/}
            {/*    <input*/}
            {/*        type="range"*/}
            {/*        min="0"*/}
            {/*        max="1"*/}
            {/*        step="0.01"*/}
            {/*        value={volume}*/}
            {/*        onChange={handleVolumeChange}*/}
            {/*        className="volume-slider-popup"*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<input type="text" placeholder="Enter your question..." className="question-input-popup"/>*/}
            <div>
                {showButtons && showEndButtons && (
                <div className="buttons-container">
                    <button className="yes-button" onClick={handleYesClick}>{t("yes")}</button>
                    <button className="no-button" onClick={handleNoClick}>{t("no")}</button>
                </div>
                )}

                {showStartTestButton && (
                <button className="start-test-button" onClick={handleStartTestClick}>Начать тест</button>
                )}
            </div>
        </div>
    );
}

export default SyntheticPopup;
