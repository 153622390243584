// src/pages/ActiveHoliday.js

import React, {useEffect, useState} from 'react';
import BackButton from '../components/BackButton';
import {useTranslation} from 'react-i18next';
import Header from "../components/Header";
import SyntheticPopup from "../synthetic/SyntheticPopup";
import {useDispatch, useSelector} from "react-redux";
import '../styles/ActiveHoliday.css';
import ChessOrderComponent from "../components/ChessOrderComponent"; // Import the CSS
import TrioImageComponent from '../components/TrioImageComponent';
import { hidePopup, setVideoFile, showPopup } from '../features/popupSlice';
import { Link } from 'react-router-dom';

const ActiveHoliday = () => {
    const { t } = useTranslation();
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const [showEndButtons, setShowEndButtons] = useState(false);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleVideoEnd = () => {
        setShowEndButtons(true);
    };

    const trio1 = {
        text1: "YouTravelComfortableHouseboat1",
        text2: "YouTravelComfortableHouseboat2",
        image1: "active-mustikka.jpg",
        image2: "active-mushroms.jpg",
        image3: "active-brusnika.jpg",
        backgroundImage: "bg-safety.jpg"
    }

    const trio2 = { 
        title: "WantSomethingFaster",
        text: "BoatRentalOptions", 
        image1: "active-RIbISO.jpg",
        image2: "active-RibPieni.jpg",
        image3: "active-MotorBoat.jpg"
    }

    const content1 = [
        { title: "ForActiveHoliday", text: "DockToContinue", image: "active-mountain-bike.jpg"},
        { title: "ForGolfEnthusiasts", text: "TryPlayingGolf", image: "active-golf.jpg", backgroundImage: "bg-safety.jpg"  },
    ];

    const content2 = [
        { title: "KayakingExcursions", text: "ActiveHolidayExplore", image: "active-kayaking.jpg", backgroundImage: "bg-safety.jpg" }
    ];

    const togglePopupVisibility = () => {
        switch (currentLanguage) {
            case "de":
                dispatch(setVideoFile("/ready-de.mp4"));
                break;
            case "en":
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
            case "fi":
                dispatch(setVideoFile("/ready-fi.mp4"));
                break;
            default:
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
        }
        dispatch(hidePopup());
    };

    useEffect(() => {
        togglePopupVisibility();
    }, [dispatch]);

    return (
        <div>
            <Header transparentHeader={true}/>
            {isPopupVisible && <SyntheticPopup
                position="right"
                autoPlay={true}
                showEndButtons={showEndButtons}
                showStartTestButton={false}
                className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
            />}
            <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                    onClick={() => {dispatch(showPopup()); handleVideoEnd()}}>
                <img src="/avatar.png" alt="Toggle Popup"/>
            </button>
            <div className="active-holiday-page">
                <h1 className="section-title">{t('Active Holiday').toUpperCase()}</h1>
                <h4 className="section-title-description">{t('TheHouseboatExperience')}</h4>
                <TrioImageComponent
                    title={t(trio1.title)}
                    text={t(trio1.text1)}
                    textSep={'Päijänne'}
                    text2={t(trio1.text2)}
                    image1={trio1.image1} 
                    image2={trio1.image2} 
                    image3={trio1.image3} 
                    backgroundImage={trio1.backgroundImage}
                    />
                {content1.map((item, index) => (
                    <ChessOrderComponent
                        key={index}
                        index={index}
                        title={t(item.title)}
                        text={t(item.text)}
                        image={item.image}
                        backgroundImage={item.backgroundImage}
                    />
                ))}
                <TrioImageComponent
                    title={t(trio2.title)}
                    text={t(trio2.text)} 
                    image1={trio2.image1} 
                    image2={trio2.image2} 
                    image3={trio2.image3}
                    />
                {content2.map((item, index) => (
                    <ChessOrderComponent
                        key={index}
                        index={index}
                        title={t(item.title)}
                        text={t(item.text)}
                        image={item.image}
                        backgroundImage={item.backgroundImage}
                    />
                ))}
            </div>
            <div className="fishing-container">
                <div className="info">
                    <h2>{t("FishingEnjoyable").toUpperCase()}</h2>
                    <p className="fishing-text">{t("DiscoverFishing")}</p>
                </div>
                <div className="rules">
                    <h4 className="rules-title">{t("FishingRules")}</h4>
                    <p>{t("NoteFishingRegulations")}</p>
                    <p><b>{t("WeOfferFishingPermits")}</b></p>    
                    <p className='permits-text'>{t("FishingPermits")}</p>
                </div>
                <div className="booking">
                    <p>{t("ConsiderFishingTrip")}</p>
                    <Link className="button" to="/booking" >{t("BookNow")}</Link>
                </div>
            </div>
            {/*<BackButton/>*/}
        </div>
    );
};

export default ActiveHoliday;