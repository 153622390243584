import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import SyntheticPopup from "../synthetic/SyntheticPopup";
import { useDispatch, useSelector } from "react-redux";
import "../styles/Contact.css"; // Import the CSS
import { hidePopup, setVideoFile, showPopup } from "../features/popupSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Contact = () => {
  const { t } = useTranslation();
  const isPopupVisible = useSelector((state) => state.popup.isPopupVisible);
  const [showEndButtons, setShowEndButtons] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [value, setValue] = useState();
  const [validEmail, setValidEmail] = useState(true);
	const [validName, setValidName] = useState(true);
  const [validSurname, setValidSurname] = useState(true);
	const [message, setMessage] = useState('');
	const [isFocused, setIsFocused] = useState(false);


  //hack for email sender
  const [hack, setHack] = useState(false);
  const handleBackBtn = () => {
    setHack(false);
  };

  const PHONE = "79255005402";
  const TELEGRAM = "NaOzere";

  const handleVideoEnd = () => {
    setShowEndButtons(true);
  };
  const [phoneError, setPhoneError] = useState("");
  const validatePhone = (phone) => {
    const phoneDigitsOnly = phone.replace(/\D/g, "");
    return phoneDigitsOnly.length >= 6 && phoneDigitsOnly.length <= 15;
  };

  useEffect(() => {
    if (value && !validatePhone(value)) {
      setPhoneError(t("invalidPhone"));
    } else {
      setPhoneError("");
    }
  }, [value, t]);

  const [formData, setFormData] = useState({
		name: '',
    surname: '',
		email: '',	
		message: '',
	});

	const maxMessageLenght = 300;
	
	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === 'name') {
      const isValidName = /^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$/.test(value);

      if (!isValidName) {
        setValidName(false);
      } else {
        setValidName(true);
      }
	  }
		
    if (name === 'surname') {
      const isValidSurname = /^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$/.test(value);

      if (!isValidSurname) {
        setValidSurname(false);
      } else {
        setValidSurname(true);
      }
	  }
		
		if (name === 'email') {
				const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

				if (!isValidEmail) {
					setValidEmail(false);
			} else {
					setValidEmail(true);
			}
	}

  if (name === 'message') {
    setMessage(value);
  }

		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phoneError && validatePhone(value) && validName && validSurname && validEmail) {
			setValue('');
      setHack(true);
    }
  };

  const togglePopupVisibility = () => {
    switch (currentLanguage) {
      case "de":
        dispatch(setVideoFile("/ready-de.mp4"));
        break;
      case "en":
        dispatch(setVideoFile("/ready-en.mp4"));
        break;
      case "fi":
        dispatch(setVideoFile("/ready-fi.mp4"));
        break;
      default:
        dispatch(setVideoFile("/ready-en.mp4"));
        break;
    }
    dispatch(hidePopup());
  };

  useEffect(() => {
    togglePopupVisibility();
  }, [dispatch]);

  return (
    <div className="contact-page">
      <Header transparentHeader={true} />
      {isPopupVisible && (
        <SyntheticPopup
          position="right"
          autoPlay={true}
          showEndButtons={showEndButtons}
          showStartTestButton={false}
          className={
            isPopupVisible ? "synthetic-popup visible" : "synthetic-popup"
          }
        />
      )}
      <button
        className={`round-button ${isPopupVisible ? "hidden" : ""}`}
        onClick={() => {
          dispatch(showPopup());
          handleVideoEnd();
        }}
      >
        <img src="/avatar.png" alt="Toggle Popup" />
      </button>
      <div className="booking-content">
        <h1>{t("Contact").toUpperCase()}</h1>
      </div>
      <div className="contact-content">
        <div className="left-content">
          <h2>{t("CallUs")}</h2>
          <span>+358 41 318 4324</span>
          <div>
            <a
              href={`https://api.whatsapp.com/send?phone=${PHONE}`}
              class="mess-icon"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-network"
                src="icon-whatsapp.png"
                alt="whatsapp"
              />
            </a>
            <a
              href={`https://telegram.me/${TELEGRAM}`}
              class="mess-icon"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-network"
                src="icon-telegram.png"
                alt="telegram"
              />
            </a>
            <a
              href={`viber://add?number${PHONE}`}
              class="mess-icon"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social-network"
                src="icon-viber.png"
                alt="viber"
              />
            </a>
          </div>
          <h2>{t("OfficeAddress")}</h2>
          <div class="widget-reveal-card-address-line widget-reveal-card-bold">
            <span>Juornatpolku 41</span>
          </div>
          <div class="widget-reveal-card-address-line">
            <span>18100 Heinola, Finland</span>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d960.4913103047481!2d26.012532970980775!3d61.21846733873722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab2c604ce53d525d!2sTeboil+T%C3%A4htihovi!5e0!3m2!1sen!2sru!4v1495444787734"
            frameborder="0"
            allowfullscreen
          />
        </div>
        <div className="right-content">
          <h2>{t("FeedbackForm")}</h2>
          <form
            className="form-content"
            onSubmit={handleSubmit}
            /*action="/contact/feedback" method="post"*/
          >
            {!hack ? (
              <>
                <div>
                  <p className="name-box">
                    <input
                      type="text"
                      name="name"
                      class="input"
                      placeholder={t('Name') + ' *'}
                      onChange={handleChange}
                      required=""
                      pattern="^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$" 
                      title={t('titleName')} 
                      value={formData.name}
                      minLength={2}
                    ></input>
                      {!validName && <span className="error">{t('invalidName')}</span>}
                    <input
                      type="text"
                      name="surname"
                      class="input"
                      placeholder={t('Surname') + ' *'}
                      onChange={handleChange}
							        required=""
							        pattern="^[a-zA-Z0-9\sšžåäöüŠŽÅÄÖÜß\-]+$"
                      title={t('titleSurname')} 
							        value={formData.surname}
                      minLength={2}
                    ></input>
                    {!validSurname && <span className="error">{t('invalidSurname')}</span>}
                  </p>
                  <p className="email-box">
                    <input
                      type="email"
                      name="email"
                      class="input"
                      placeholder="Email *"
                      onChange={handleChange}
                      required=""
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title={t('titleEmail')}
                      value={formData.email}
                    ></input>
                    {!validEmail && <span className="error">{t('invalidEmail')}</span>}
                  </p>
                  <p className="phone-box">
                    <PhoneInput
                      placeholder={t('PhoneNumber') + ' *'}
                      value={value}
                      onChange={setValue}
                      defaultCountry="FI"
                      international
                      pattern={`.{14,}`}
                      title={t('titlePhone')}
                      minLength={6}
                      maxLength={15}
                    />
                  </p>
                  <div className="email-box">
                    <textarea
                      name="message"
                      class="textarea"
                      placeholder={t('Message') + ' *'}
                      rows="5"
                      required
                      onChange={handleChange}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      maxLength={maxMessageLenght}
                      minLength={10}
                    ></textarea>
                      {isFocused && message && (<p className='message-lenght'>Remaining characters: {maxMessageLenght - message.length}</p>)}
                  </div>
                </div>
                <input
                  type="submit"
                  value={t("Send").toUpperCase()}
                  class="btn"
                ></input>
              </>
            ) : (
              <div>
                <h1>{t("Successful")}</h1>
                <p>{t("FeedbackRequestConfirmation")}</p>
                <button type="button" onClick={handleBackBtn}>
                  {t("Back").toUpperCase()}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      {/*<BackButton/>*/}
    </div>
  );
};

export default Contact;
