// popupSlice.js
import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        isPopupVisible: false,
        videoFile: '',
    },
    reducers: {
        showPopup: (state) => {
            state.isPopupVisible = true;
        },
        hidePopup: (state) => {
            state.isPopupVisible = false;
        },
        setVideoFile: (state, action) => {
            state.videoFile = action.payload;
        },
    },
});

export const { showPopup, hidePopup, setVideoFile } = popupSlice.actions;
export default popupSlice.reducer;
