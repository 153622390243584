// src/components/Header.js
import React, {useState, useEffect, useRef} from 'react';
import {getCustomStyles} from '../styles/custom/customStyles';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import '../styles/Header.css';
import Select from 'react-select';
import {useTransition, animated} from '@react-spring/web'
import MenuList from "./MenuList";
import {useDispatch, useSelector} from 'react-redux';
import {toggleHeaderVisibility, toggleMenu} from '../features/burgerMenuSlice';
import {showPopup, hidePopup, setVideoFile} from '../features/popupSlice';
import {showLanguageMenu, hideLanguageMenu} from '../features/languageMenuSlice';


const Header = ({backgroundImage, transparentHeader}) => {
    const {t, i18n} = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dispatch = useDispatch();
    const isMenuOpen = useSelector(state => state.burgerMenu.isOpen);
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const videoFile = useSelector(state => state.popup.videoFile);
    const isLanguageMenuOpen = useSelector(state => state.languageMenu.isVisible);
    const [bgColor, setBgColor] = useState('defaultColor');
    const location = useLocation();
    const menuRef = useRef(null);
    const timeoutRef = useRef(null);

    const currentPage = location.pathname.split('/')[1];
    
    const transitions = useTransition(isMenuOpen, {
        from: {transform: 'translate3d(-100%,0,0)', position: 'absolute'},
        enter: {transform: 'translate3d(-20px,0,0)', position: 'absolute'},
        leave: {transform: 'translate3d(-100%,0,0)', position: 'absolute'},
        config: {duration: 300},
    });

    const handleMenuClose = () => {
        dispatch(hideLanguageMenu()); // переключаем состояние меню при закрытии
    };

    const handleMenuOpen = () => {
        dispatch(showLanguageMenu()); // переключаем состояние меню при закрытии
    };

    useEffect(() => {
        const pathStyles = {
            '/': '#f9fbf8',
            '/houseboat': '#ced2de',
            '/booking': '#ced2de',
            '/training': '#ced2de',
            '/safety-on-water': '#ced2de',
            '/active-holiday': '#ced2de',    
            '/rental-terms': '#ced2de',
            '/contact': '#dfdfdf',
        };
        setBgColor(pathStyles[location.pathname] || '#BCDFFB');
    }, [location.pathname]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && isMenuOpen) {
                dispatch(toggleMenu());
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            timeoutRef.current = setTimeout(() => {
                dispatch(toggleMenu());
            }, 10000);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [dispatch, isMenuOpen]);

    const videos = {
        en: "/home-hello-en.mp4",
        fi: "/home-hello-fi.mp4",
        de: "/home-hello-de.mp4",
    };

    const changeLanguage = async (option) => {
        try {
            await i18n.changeLanguage(option.value);
            dispatch(setVideoFile(videos[option.value] || videos['en']));
            dispatch(showPopup());
        } catch (error) {
            console.error('Failed to change language:', error);
        }
    };

    const languageOptions = [  // Определение опций для выпадающего списка
        {value: 'en', label: 'English (EN)'},
        {value: 'fi', label: 'Suomi (FI)'},
        {value: 'de', label: 'Deutsch (DE)'},
    ];


    const headerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: transparentHeader ? 'transparent' : undefined
    };

    const customStyles = getCustomStyles(isMobile, currentPage);

    const handleLinkClick = () => {
        dispatch(toggleMenu());
    };

    return (
        <header className="header" style={headerStyle}>
            <div className="top-row">
                <div className="logo-and-button">
                    <div className="logo">
                        <Link to="/">
                            <img src="/logo-aquavilla.png" alt="Company Logo"/>
                        </Link>
                        <Link style={{ cursor: 'auto' }}>
                            <img src="/logo-aquavilla-sep.png" alt="Company Logo"/>
                        </Link>
                        <Link to="/">
                            <img src="/logo-aquavilla-text.png" alt="Company Logo" />
                        </Link>
                    </div>
                    {location.pathname !== "/booking" && (
                        <Link to="/booking">
                            <button className="book-button">{t('bookNow')}</button>
                        </Link>
                    )}
                </div>
                <div className="language-select">
                    <Select
                        options={languageOptions}
                        defaultValue={languageOptions.find(option => option.value === i18n.language)}  // Установите значение для выбранного языка
                        onChange={changeLanguage}
                        className="language-select"
                        isSearchable={false}
                        menuIsOpen={isLanguageMenuOpen}
                        onMenuClose={handleMenuClose}
                        onMenuOpen={handleMenuOpen}
                        onInputChange={() => {
                        }}
                        styles={customStyles}/>
                </div>
            </div>
            {isMobile ? (
                <>
                    <button type="button" className="burger-button" onClick={() => dispatch(toggleMenu())}>
                        {/* Пустой контент, так как стили создают линии */}
                    </button>
                    {transitions((style, item) => item && (
                        <animated.nav
                            style={{ ...style, backgroundColor: bgColor }}
                            className="mobile-nav bottom-row"
                            ref={menuRef}
                            onRest={() => {
                                if (!item) {
                                    setTimeout(() => {
                                        dispatch(toggleHeaderVisibility());
                                    }, 300);  // 300ms задержка, соответствующая задержке в CSS
                                }
                            }}
                        >
                            <MenuList onLinkClick={handleLinkClick} />
                        </animated.nav>
                    ))}
                </>
            ) : (
                <nav className="desktop-nav nav">
                    <MenuList/>
                </nav>
            )}
        </header>
    );
};

export default Header;
