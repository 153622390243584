// src/pages/Training.js

import React, {useEffect, useState} from 'react';
import BackButton from '../components/BackButton';
import {useTranslation} from 'react-i18next';
import Header from "../components/Header";
import SyntheticPopup from "../synthetic/SyntheticPopup";
import {useDispatch, useSelector} from "react-redux";
import '../styles/Training.css';
import {setVideoFile, showPopup} from "../features/popupSlice"; // Import the CSS

const Training = () => {
    const { t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const [showEndButtons, setShowEndButtons] = useState(false);
    const dispatch = useDispatch();

    const handleVideoEnd = () => {
        setShowEndButtons(true);
    };

    const togglePopupVisibility = () => {
        switch (currentLanguage) {
            case "en":
                dispatch(setVideoFile("/training-intro-en.mp4"));
                dispatch(showPopup());
                handleVideoEnd();
                break;
            case "fi":
                dispatch(setVideoFile("/training-intro-fi.mp4"));
                dispatch(showPopup());
                handleVideoEnd();
                break;
            case "de":
                dispatch(setVideoFile("/training-intro-de.mp4"));
                dispatch(showPopup());
                handleVideoEnd();
                break;
            default:
                dispatch(setVideoFile("/training-intro-en.mp4"));
                dispatch(showPopup());
                handleVideoEnd();
                break;
        }
    };

    useEffect(() => {
        togglePopupVisibility();
    }, [dispatch]);

    return (
        <div>
            <Header transparentHeader={true}/>
            {isPopupVisible && <SyntheticPopup
                position="right"
                autoPlay={true}
                showEndButtons={showEndButtons}
                showStartTestButton={false}
                className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
            />}
            <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                    onClick={togglePopupVisibility}>
                <img src="/avatar.png" alt="Toggle Popup"/>
            </button>
            <div className="training-page-section" id="training-page-section" style={{backgroundImage: 'url(/bg-training.jpg)'}}>
                <h1 className="section-title">{t('houseboatTraining')}</h1>

                <div>
                    <p className="training-text">{t('trainingExperience1')}</p>
                    <p className="training-text">{t('trainingExperience2')}</p>
                    <p className="training-text">{t('trainingExperience3')}</p>
                    <p className="training-text">{t('trainingExperience4')}</p>
                    <p className="training-text">{t('trainingExperience5')}</p>
                    <p className="training-text">{t('trainingExperience6')}</p>
                </div>
            </div>
        </div>
    );
};

export default Training;