// languageMenuSlice.js
import { createSlice } from '@reduxjs/toolkit';

const languageMenuSlice = createSlice({
    name: 'languageMenu',
    initialState: {
        isVisible: false,
    },
    reducers: {
        showLanguageMenu: (state) => {
            state.isVisible = true;
        },
        hideLanguageMenu: (state) => {
            state.isVisible = false;
        },
    },
});

export const { showLanguageMenu, hideLanguageMenu } = languageMenuSlice.actions;
export default languageMenuSlice.reducer;
