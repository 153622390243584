// src/components/ChessOrderComponent.js

import React from 'react';
import '../styles/ChessOrderComponent.css';

const ChessOrderComponent = ({ index, title, text, image, backgroundImage }) => {
    const isTextOnLeft = index % 2 === 0;

    return (
        <div className={`chess-order-container ${backgroundImage ? 'with-bg' : ''}`} style={{backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none'}}>
            <div className="chess-order-box">
                <div className={`chess-text-container ${isTextOnLeft ? 'left' : 'right'}`}>
                    <p className="chess-title">{title}</p>
                    <p className="chess-text">{text}</p>
                </div>
                <div className={`chess-image-container ${isTextOnLeft ? 'right' : 'left'}`}>
                    <img src={image} alt="Chess Order Visual" />
                </div> 
            </div>
        </div>
    );
};

export default ChessOrderComponent;
