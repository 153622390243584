// src/components/MenuList.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/MenuList.css';

const MenuList = ({ onLinkClick }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const checkCurrentPage = (currLocation) => {
        return location.pathname === currLocation;
    }

    return (
        <ul>
            <li className={checkCurrentPage("/houseboat") ? 'active-page' : ''}><Link to="/houseboat" onClick={onLinkClick}>{t('Houseboat').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/booking") ? 'active-page' : ''}><Link to="/booking" onClick={onLinkClick}>{t('Booking').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/training") ? 'active-page' : ''}><Link to="/training" onClick={onLinkClick}>{t('Training').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/safety-on-water") ? 'active-page' : ''}><Link to="/safety-on-water" onClick={onLinkClick}>{t('Safety on Water').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/active-holiday") ? 'active-page' : ''}><Link to="/active-holiday" onClick={onLinkClick}>{t('Active Holiday').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/rental-terms") ? 'active-page' : ''}><Link to="/rental-terms" onClick={onLinkClick}>{t('Rental Terms').toUpperCase()}</Link></li>
            <li className={checkCurrentPage("/contact") ? 'active-page' : ''}><Link to="/contact" onClick={onLinkClick}>{t('Contact').toUpperCase()}</Link></li>
            {/* <li><Link to="/blog" onClick={onLinkClick}>{t('Blog')}</Link></li> */}
        </ul>
    );
};

export default MenuList;
