// src/pages/RentalTerms.js

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Header from "../components/Header";
import SyntheticPopup from "../synthetic/SyntheticPopup";
import {useDispatch, useSelector} from "react-redux";
import '../styles/RentalTerms.css';
import {hidePopup, setVideoFile, showPopup} from "../features/popupSlice";

const RentalTerms = () => {
    const { t } = useTranslation();
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const [showEndButtons, setShowEndButtons] = useState(false);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const handleVideoEnd = () => {
        setShowEndButtons(true);
    };

    const togglePopupVisibility = () => {
        switch (currentLanguage) {
            case "de":
                dispatch(setVideoFile("/ready-de.mp4"));
                break;
            case "en":
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
            case "fi":
                dispatch(setVideoFile("/ready-fi.mp4"));
                break;
            default:
                dispatch(setVideoFile("/ready-en.mp4"));
                break;
        }
        dispatch(hidePopup());
    };

    useEffect(() => {
        togglePopupVisibility();
    }, [dispatch]);

    return (
        <div>
            <Header transparentHeader={true}/>
            {isPopupVisible && <SyntheticPopup
                position="right"
                autoPlay={true}
                showEndButtons={showEndButtons}
                showStartTestButton={false}
                className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
            />}
            <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                    onClick={() => {dispatch(showPopup()); handleVideoEnd()}}>
                <img src="/avatar.png" alt="Toggle Popup"/>
            </button>
            <div className="rental-terms-page-section" id="rental-terms-page-section" style={{backgroundImage: 'url(/bg-rent-1000x500.jpg)'}}>
                <h1 className="section-title">{t('rentalTerms')}</h1>

                <div className="contract-content">
                    <section>
                        <h2>{t('landlordDuties')}</h2>
                        <article>
                            <h3>1.1</h3>
                            <p>{t('renterResponsibility')}</p>
                        </article>
                        <article>
                            <h3>1.2</h3>
                            <p>{t('appointedCaptainResponsibility')}</p>
                        </article>
                        <article>
                            <h3>1.3</h3>
                            <p>{t('propertySafetyResponsibility')}</p>
                        </article>
                        <article>
                            <h3>1.4</h3>
                            <p>{t('handoverProcedure')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('boatAndEquipmentResponsibilityDuringRental')}</h2>
                        <article>
                            <h3>2.1</h3>
                            <p>{t('renterDepositRequirement')}</p>
                            <ul>
                                <p>- {t('damageCoverage')}</p>
                                <p>- {t('replacementOfLostParts')}</p>
                            </ul>
                        </article>
                        <article>
                            <h3>2.2</h3>
                            <p>{t('renterLiabilityForDamage')}</p>
                        </article>
                        <article>
                            <h3>2.3</h3>
                            <p>{t('renterReleaseFromLiability')}</p>
                            <p>{t('renterLiabilityReleaseInsurance')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('insuranceSection')}</h2>
                        <article>
                            <h3>3.1</h3>
                            <p>{t('fullBoatInsurance')}</p>
                        </article>
                        <article>
                            <h3>3.2</h3>
                            <p>{t('insuranceTerms')}</p>
                        </article>
                        <article>
                            <h3>3.3</h3>
                            <p>{t('insuranceExclusions')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('rentPaymentSection')}</h2>
                        <article>
                            <h3>4.1</h3>
                            <p>{t('rentInclusions')}</p>
                        </article>
                        <article>
                            <h3>4.2</h3>
                            <p>{t('bookingConfirmationAndPayment')}</p>
                        </article>
                        <article>
                            <h3>4.3</h3>
                            <p>{t('renterDepositPayment')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('rentalCompanyResponsibilities')}</h2>
                        <article>
                            <h3>5.1</h3>
                            <p>{t('BoatHandoverResponsibility')}</p>
                        </article>
                        <article>
                            <h3>5.2</h3>
                            <p>{t('RentalCompanyInstructionResponsibility')}</p>
                        </article>
                        <article>
                            <h3>5.3</h3>
                            <p>{t('DelayedHandoverRights')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('FuelAndBoatMaintenanceSection')}</h2>
                        <article>
                            <h3>6.1</h3>
                            <p>{t('FuelResponsibility')}</p>
                        </article>
                        <article>
                            <h3>6.2</h3>
                            <p>{t('FuelInformation')}</p>
                        </article>
                        <article>
                            <h3>6.3</h3>
                            <p>{t('FuelMisuseResponsibility')}</p>
                        </article>
                        <article>
                            <h3>6.4</h3>
                            <p>{t('RenterMaintenanceResponsibility')}</p>
                        </article>
                        <article>
                            <h3>6.5</h3>
                            <p>{t('BoatReturnCleanliness')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('RenterResponsibilityInCaseOfErrorsAndDamages')}</h2>
                        <article>
                            <h3>7.1</h3>
                            <p>{t('RenterReportingResponsibility')}</p>
                        </article>
                        <article>
                            <h3>7.2</h3>
                            <p>{t('RenterDamageReportingResponsibility')}</p>
                        </article>
                        <article>
                            <h3>7.3</h3>
                            <p>{t('ConsequencesOfRenterNeglect')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('RentalCompanyResponsibility')}</h2>
                        <article>
                            <h3>8.1</h3>
                            <p>{t('RentalCompanyTechnicalFaultResponsibility')}</p>
                        </article>
                        <article>
                            <h3>8.2</h3>
                            <p>{t('RentalCompanyIndirectCostsDisclaimer')}</p>
                        </article>
                        <article>
                            <h3>8.3</h3>
                            <p>{t('RentalCompanyCompensationLimit')}</p>
                        </article>
                        <article>
                            <h3>8.4</h3>
                            <p>{t('LateReportResponsibility')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('BoatReturnAfterRent')}</h2>
                        <article>
                            <h3>9.1</h3>
                            <p>{t('HouseboatReturnRequirements')}</p>
                        </article>
                        <article>
                            <h3>9.2</h3>
                            <p>{t('ReturnWithinAgreedRentalPeriod')}</p>
                        </article>
                        <article>
                            <h3>9.3</h3>
                            <p>{t('NoRefundForEarlyReturn')}</p>
                        </article>
                        <article>
                            <h3>9.4</h3>
                            <p>{t('ConsequencesOfNeglectingResponsibilities')}</p>
                        </article>
                        <article>
                            <h3>9.5</h3>
                            <p>{t('RenterReportingFaults')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('ContractTermination')}</h2>
                        <article>
                            <h3>10.1</h3>
                            <p>{t('RentalCompanyRightToTerminate')}</p>
                        </article>
                        <article>
                            <h3>10.2</h3>
                            <p>{t('RentalCompanyRightToTerminateBeforeRental')}</p>
                        </article>
                        <article>
                            <h3>10.3</h3>
                            <p>{t('RenterRightBeforeRental')}</p>
                        </article>
                        <article>
                            <h3>10.4</h3>
                            <p>{t('TerminationRequestOtherThanSection10.3')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('TakingBoatOutsideSailingArea')}</h2>
                        <article>
                            <h3>11.1</h3>
                            <p>{t('ProhibitionOfTakingBoatOutsideSailingArea')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('SmokingInHouseboat')}</h2>
                        <article>
                            <h3>12.1</h3>
                            <p>{t('NoSmokingInHouseboatInterior')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('Pets')}</h2>
                        <article>
                            <h3>13.1</h3>
                            <p>{t('PetsWelcomeFree')}</p>
                        </article>
                    </section>

                    <section>
                        <h2>{t('DisputesRegardingAgreement')}</h2>
                        <article>
                            <h3>14.1</h3>
                            <p>{t('IfDisagreementsCannotBeResolved')}</p>
                        </article>
                    </section>

                </div>
            </div>
        </div>
    );
};

export default RentalTerms;