// src/pages/Houseboat.js
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import "../styles/Houseboat.css";
import Header from '../components/Header';
import SyntheticPopup from "../synthetic/SyntheticPopup";
import {useDispatch, useSelector} from "react-redux";
import {hidePopup, setVideoFile, showPopup} from "../features/popupSlice";
import { useNavigate } from 'react-router-dom';



function Houseboat() {
    const { t, i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const isPopupVisible = useSelector(state => state.popup.isPopupVisible);
    const [activeSection, setActiveSection] = useState(null);
    const dispatch = useDispatch();
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [showGallery, setShowGallery] = useState(false);
    const [currentGalleryImages, setCurrentGalleryImages] = useState([]);
    const navigate = useNavigate();


    const galleryImagesForButton0 = ["/houseboat-gallery0-1.jpg", "/houseboat-gallery0-2.jpg", "/houseboat-gallery0-3.jpg", "/houseboat-gallery0-4.jpg", "/houseboat-gallery0-4.jpg", "/houseboat-gallery0-6.jpg"];
    const galleryImagesForButton1 = ["/houseboat-gallery1-1.jpg", "/houseboat-gallery1-2.jpg", "/houseboat-gallery1-3.jpg", "/houseboat-gallery1-4.jpg", "/houseboat-gallery1-5.jpg", "/houseboat-gallery1-6.jpg"];
    const galleryImagesForButton2 = ["/houseboat-gallery2-1.jpg", "/houseboat-gallery2-2.jpg", "/houseboat-gallery2-3.jpg", "/houseboat-gallery2-4.jpg", "/houseboat-gallery2-5.jpg","/main-gallery4-1.jpg","/main-gallery1-2.jpg","/main-gallery3-7.jpg", "/houseboat-gallery2-6.jpg"];
    const galleryImagesForButton3 = ["/houseboat-gallery3-1.jpg", "/houseboat-gallery3-2.jpg", "/houseboat-gallery3-3.jpg", "/houseboat-gallery3-4.jpg", "/houseboat-gallery3-5.jpg"];


    const videoMap = {
        "houseboat-page-section": "/home-select-lang-en.mp4",
    };

    const onClose = () => {
        dispatch(hidePopup());
    };

    const getVideoPath = (index, language) => {
        const baseVideoPath = `/houseboat-button-${index}`;
        switch (language) {
            case 'en':
                return `${baseVideoPath}-en.mp4`;
            case 'fi':
                return `${baseVideoPath}-fi.mp4`;
            case 'de':
                return `${baseVideoPath}-de.mp4`;
            default:
                return `${baseVideoPath}-en.mp4`;
        }
    };

    const handleButtonClick = (index) => {
        let videoPath = getVideoPath(index, currentLanguage);
        let selectedGalleryImages;

        if (index === 2) {
            const languageSuffix = currentLanguage === 'en' ? '_en' : currentLanguage === 'de' ? '_de' : '';
            selectedGalleryImages = galleryImagesForButton2.map(img => 
                img.replace('/houseboat-gallery2-6.jpg', `/houseboat-gallery2-6${languageSuffix}.jpg`)
            );
        } else {
            switch(index) {
                case 0:
                    selectedGalleryImages = galleryImagesForButton0;
                    break;
                case 1:
                    selectedGalleryImages = galleryImagesForButton1;
                    break;
                case 3:
                    selectedGalleryImages = galleryImagesForButton3;
                    break;
                default:
                    videoPath = "/ready-en.mp4";
                    selectedGalleryImages = [];
            }
        }
        setCurrentGalleryImages(selectedGalleryImages);
        requestAnimationFrame(() => {
            setShowGallery(true);
        });
        setGalleryIndex(0);
        dispatch(setVideoFile(videoPath));
        dispatch(showPopup());
    };

    const buttonsData = [
        {image: '/pr-1-1.png', titleKey: 'comfortable', descriptionKey: 'comfortsText'},
        {image: '/pr-2-1.png', titleKey: 'free', descriptionKey: 'houseboatText'},
        {image: '/pr-3-1.png', titleKey: 'safe', descriptionKey: 'navigationText'},
        {image: '/pr-4-1.png', titleKey: 'ecological', descriptionKey: 'ecoFeaturesText'},
    ];

    const togglePopupVisibility = () => {
        switch (currentLanguage) {
            case "en":
                dispatch(setVideoFile("/houseboat-intro-en.mp4"));
                dispatch(showPopup());
                break;
            case "fi":
                dispatch(setVideoFile("/houseboat-intro-fi.mp4"));
                dispatch(showPopup());
                break;
            case "de":
                dispatch(setVideoFile("/houseboat-intro-de.mp4"));
                dispatch(showPopup());
                break;
            default:
                dispatch(setVideoFile("/houseboat-intro-en.mp4"));
                dispatch(showPopup());
                break;
        }
    };

    useEffect(() => {
        if (showGallery) {
            const interval = setInterval(() => {
                setGalleryIndex((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    if (nextIndex >= currentGalleryImages.length) {
                        setShowGallery(false);
                        return 0;
                    }
                    return nextIndex;
                });
            }, 2000);

            return () => clearInterval(interval);
        }
    }, [showGallery, currentGalleryImages]);

    useEffect(() => {
        if (!isPopupVisible) {
            setShowGallery(false);
        }
    }, [isPopupVisible]);

    useEffect(() => {
            togglePopupVisibility();
    }, [dispatch]);

    useEffect(() => {
        const updatedGalleryImages = galleryImagesForButton2.map(img => 
            img.replace('/houseboat-gallery2-6.jpg', `/houseboat-gallery2-6${currentLanguage === 'en' ? '_en' : currentLanguage === 'de' ? '_de' : ''}.jpg`)
        );
        setCurrentGalleryImages(updatedGalleryImages);
    }, [currentLanguage]);
    
    return (
        <div>
            <Header transparentHeader={true}/>
            {isPopupVisible && <SyntheticPopup
                position="right"
                autoPlay={true}
                showEndButtons={false}
                showStartTestButton={false}
                className={isPopupVisible ? 'synthetic-popup visible' : 'synthetic-popup'}
            />}
            <button className={`round-button ${isPopupVisible ? 'hidden' : ''}`}
                    onClick={togglePopupVisibility}>
                <img src="/avatar.png" alt="Toggle Popup"/>
            </button>
            <div className="houseboat-page-section" id="houseboat-page-section" style={{backgroundImage: 'url(/bg-main2.jpg)'}}>
                <h1 className="section-title">{t('houseboatVacation')}</h1>
                <div className="buttons-row">
                    {buttonsData.map((button, index) => (
                        <div className="button-block" key={index}>
                            <button
                                className={`button-item button-${index}`}
                                onClick={() => handleButtonClick(index)}
                                style={{backgroundImage: `url(${button.image})`}}
                            >
                            </button>
                            <h2 className="button-title">{t(button.titleKey)}</h2>
                            <p className="button-description">{t(button.descriptionKey)}</p>
                        </div>
                    ))}
                </div>
            </div>
            {showGallery && (
                    <>
                        <div class="overlay" onClick={onClose}></div>
                        <div className={`gallery ${showGallery ? 'active' : ''}`}>
                            <i className="cl-btn-gallery" onClick={onClose}></i>
                            <img src={currentGalleryImages[galleryIndex]} alt="Gallery Image" />
                        </div>
                    </>
            )}
        </div>
    );
}

export default Houseboat;
