import React, { useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import '../styles/Footer.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<footer className="footer_">
			<div className="footer-max-width">
				<div className="footerLogo">
					<div className="footer-logo-container">
						<img
							src="/logo2-white.png"
							alt="Your Logo"
							className="footer-logo"
						/>
					</div>
				</div>
				<div className="footerTop">

					<div className="my-nav">
						<NavLink to="/houseboat" className="nav-link" activeClassName="active">{t('Houseboat')}</NavLink>
						<NavLink to="/booking" className="nav-link" activeClassName="active">{t('Booking')}</NavLink>
						<NavLink to="/training" className="nav-link" activeClassName="active">{t('Training')}</NavLink>
						<NavLink to="/safety-on-water" className="nav-link" activeClassName="active">{t('Safety on Water')}</NavLink>
						<NavLink to="/active-holiday" className="nav-link" activeClassName="active">{t('Active Holiday')}</NavLink>
						<NavLink to="/rental-terms" className="nav-link" activeClassName="active">{t('Rental Terms')}</NavLink>
						<NavLink to="/contact" className="nav-link" activeClassName="active">{t('Contact')}</NavLink>
						{/* <a href="/blog" className="nav-link">Blog</a> */}
					</div>
				</div>
				<div className="footerBottom">

					<div className="my-social">
						<a
							href="https://www.facebook.com/aquavilla"
							target="_blank"
							rel="noopener noreferrer"
							className="social-icon"
						>
							<FontAwesomeIcon icon={faFacebook} />
						</a>
						<a
							href="https://www.instagram.com/aquavilla"
							target="_blank"
							rel="noopener noreferrer"
							className="social-icon"
						>
							<FontAwesomeIcon icon={faInstagram} />
						</a>
					</div>

					<div className="copyright">
						<div className="text-center">
							<p className="copyright">
								{t('Copyright')}
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
